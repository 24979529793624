import { filter, orderBy } from "lodash";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { Card } from "@/components/ui/card";
import { Icon, IconString } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Pane } from "@/components/ui/pane";
import { StateIndicator } from "@/metadata";
import {
  AgencyFragment,
  InsuredDeepFragment,
  MarketingPlanState,
  QuoteState,
  useInsuredsDeepQuery,
} from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { InsuredPin } from "../components/ui/insured-pin";
import { InsuredCard } from "./insured-card";

export const InsuredsList = ({ id, agency }: { id: string; agency: AgencyFragment }) => {
  const { data: { insureds = [] } = {}, loading: loadingInsureds } = useInsuredsDeepQuery({
    variables: {
      input: { agencyId: id },
    },
    skip: !id,
    fetchPolicy: "cache-first",
  });

  useDocumentTitle(`${agency.name}: QuoteWell`);

  if (loadingInsureds) {
    return (
      <Pane>
        <Loading label="Loading Insureds..." />
      </Pane>
    );
  }

  const sortedInsureds = orderBy(
    insureds,
    (insured) => Math.max(...insured.opportunities.map((opp) => Date.parse(opp.updatedAt)), 0),
    "desc"
  );

  const infoGathering =
    sortedInsureds.filter((insured) =>
      insured.opportunities.some((opp) => opp.state === MarketingPlanState.InformationGathering)
    ) ?? [];

  const activeMarketingPlans =
    sortedInsureds.filter((insured) =>
      insured.opportunities.some((opp) => opp.state !== MarketingPlanState.Complete)
    ) ?? [];

  const deliveredQuotes = filter(sortedInsureds, {
    opportunities: [{ submissions: [{ quotes: [{ state: QuoteState.Delivered }] }] }],
  });

  return (
    <Pane layout="list">
      {infoGathering[0] && (
        <div className="border-b px-4 py-6 space-y-6">
          <H3>
            <Badge icon="priority_high" color="amber" />
            {agency?.name} has {infoGathering.length} outstanding information request
            {infoGathering.length > 1 && "s"}.
          </H3>
          <div className="gap-4 grid sm:grid-cols-2 md:grid-cols-3 xl:md:grid-cols-4 2xl:md:grid-cols-5 4xl:md:grid-cols-6 items-start">
            {infoGathering.map((insured) => (
              <InsuredCard key={insured.id} insured={insured} />
            ))}
          </div>
        </div>
      )}
      {deliveredQuotes[0] && (
        <div className="border-b px-4 py-6 space-y-6">
          <H3>
            <Badge icon="request_quote" color="green" />
            {agency?.name} has {deliveredQuotes.length} quote{deliveredQuotes.length > 1 && "s"} to review.
          </H3>
          <div className="gap-4 grid sm:grid-cols-2 md:grid-cols-3 xl:md:grid-cols-4 2xl:md:grid-cols-5 4xl:md:grid-cols-6 items-start">
            {deliveredQuotes.map((insured) => (
              <InsuredCard key={insured.id} insured={insured} />
            ))}
          </div>
        </div>
      )}
      <div className="px-4 py-6 space-y-6">
        <H3>
          <Badge icon="campaign" color="blue" />
          {agency?.name} has {activeMarketingPlans.length} active Marketing Plans.
        </H3>
        {activeMarketingPlans[0] && (
          <Card>
            <InsuredHeader />
            {activeMarketingPlans.map((insured) => (
              <InsuredRow key={insured.id} insured={insured} />
            ))}
          </Card>
        )}
      </div>
    </Pane>
  );
};

const Badge = ({ color, icon }: { color: string; icon: IconString }) => (
  <span
    className={`bg-background border-2 flex flex-none h-8 items-center justify-center leading-none rounded-full w-8`}
  >
    <Icon icon={icon} className={`filled text-lg text-${color}-600`} />
  </span>
);

const H3 = ({ children }: { children: React.ReactNode }) => (
  <h3 className="flex gap-3 items-center leading-tight text-base">{children}</h3>
);

const InsuredHeader = () => (
  <header className="border-b gap-4 grid grid-cols-[repeat(7,_1fr)_auto] font-semibold mb-px px-4 py-3 text-2xs text-muted-foreground">
    <div>Insured</div>
    <div>Lines</div>
    <div>Agent</div>
    <div>Broker</div>
    <div>Eff. Date</div>
    <div>Status</div>
    <div>Updated</div>
  </header>
);

const InsuredRow = ({ insured: { id: insuredId, name, agent, opportunities } }: { insured: InsuredDeepFragment }) => (
  <div key={insuredId} className="even:bg-background odd:bg-accent border-t py-2 last:rounded-b-lg text-xs">
    {opportunities.map((opp, key) => (
      <Link
        to={`/insured/${insuredId}/${opp.id}`}
        key={opp.id}
        className="gap-4 grid grid-cols-[repeat(7,_1fr)_auto] px-4 py-2"
      >
        <div>{key === 0 && name}</div>
        <div className="font-semibold space-y-1">
          {opp.selectedLinesOfBusiness[0] ? (
            opp.selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
          ) : (
            <div>No lines of business</div>
          )}
        </div>
        <div>
          {agent.firstName} {agent.lastName}
        </div>
        <div>{opp.broker ? `${opp.broker?.firstName} ${opp.broker?.lastName}` : "Unassigned"}</div>
        <div>{formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d, YYY")}</div>
        <div>
          <StateIndicator state={opp.state} />
        </div>
        <div>{formatTimezoneDateString(opp.updatedAt, "MMM d, YYY")}</div>
        <div className="col-auto">
          <InsuredPin id={insuredId} />
        </div>
      </Link>
    ))}
  </div>
);
