import { MarketingPlanState, PolicyState, QuoteState, SubmissionState } from "../generated/graphql";
import { CardDescription, CardTitle } from "./components/ui/card";
import { Icon, IconString } from "./components/ui/icon";
import { cn } from "./utils";

interface StateMetadata {
  label: string;
  className: string;
  cardClassName: string;
  icon: IconString;
  agentMessage?: React.ReactNode;
}

export type States = MarketingPlanState | SubmissionState | QuoteState | PolicyState;

export const MARKETING_PLAN_STATE_METADATA: Record<MarketingPlanState, StateMetadata> = {
  [MarketingPlanState.WaitingForCoverages]: {
    className: "text-slate-600",
    cardClassName: "bg-slate-500/5 border-slate-500/80",
    icon: "shield",
    label: "Waiting for Coverage",
    agentMessage: (
      <>
        <CardTitle>No coverage selected.</CardTitle>
        <CardDescription>Please select the coverage required by the Insured.</CardDescription>
      </>
    ),
  },
  [MarketingPlanState.InformationGathering]: {
    className: "filled text-red-600",
    cardClassName: "bg-red-500/5 border-red-500/80",
    icon: "warning",
    label: "Information Required",
    agentMessage: (
      <>
        <CardTitle>We need some more information.</CardTitle>
        <CardDescription>Please upload the required information we need to go to market.</CardDescription>
      </>
    ),
  },
  [MarketingPlanState.InformationReview]: {
    className: "text-orange-600",
    cardClassName: "bg-orange-500/5 border-orange-500/80",
    icon: "info",
    label: "Broker Review",
    agentMessage: (
      <>
        <CardTitle>Your broker is reviewing your submission now.</CardTitle>
        <CardDescription>We&apos;ll let you know if we need anything else to get started on quotes!</CardDescription>
      </>
    ),
  },
  [MarketingPlanState.Marketing]: {
    className: "filled text-emerald-600",
    cardClassName: "bg-emerald-500/5 border-emerald-500/80",
    icon: "campaign",
    label: "Marketing",
    agentMessage: (
      <>
        <CardTitle>We&apos;re out to market!</CardTitle>
        <CardDescription>
          Your broker is working on getting quotes. Check back here for updates as we get results.
        </CardDescription>
      </>
    ),
  },
  [MarketingPlanState.Complete]: {
    className: "filled text-purple-600",
    cardClassName: "bg-purple-500/5 border-purple-500/80",
    icon: "verified",
    label: "Marketing Complete",
    agentMessage: (
      <>
        <CardTitle>Thank you for giving QuoteWell a chance to win your business!</CardTitle>
        <CardDescription>
          Your broker has completed marketing for this plan. Check the <strong>Proposals</strong> and{" "}
          <strong>Marketing Plans</strong> sections for applicable quotes and alternatives.
        </CardDescription>
      </>
    ),
  },
};

export const SUBMISSION_STATE_METADATA: Record<SubmissionState, StateMetadata> = {
  [SubmissionState.Pending]: {
    className: "text-slate-600",
    cardClassName: "bg-slate-500/5 border-slate-500/80",
    icon: "shield",
    label: "Pending",
  },
  [SubmissionState.Submitted]: {
    className: "filled text-orange-600",
    cardClassName: "bg-orange-500/5 border-orange-500/80",
    icon: "campaign",
    label: "Submitted",
  },
  [SubmissionState.MarketBlocked]: {
    className: "text-slate-600",
    cardClassName: "bg-slate-500/5 border-slate-500/80",
    icon: "front_hand",
    label: "Blocked",
  },
  [SubmissionState.Declined]: {
    className: "text-red-600",
    cardClassName: "bg-red-500/5 border-red-500/80",
    icon: "thumb_down",
    label: "Declined",
  },
  [SubmissionState.Referred]: {
    className: "filled text-orange-600",
    cardClassName: "bg-orange-500/5 border-orange-500/80",
    icon: "send",
    label: "Referred",
  },
  [SubmissionState.RuledOut]: {
    className: "text-slate-600",
    cardClassName: "bg-slate-500/5 border-slate-500/80",
    icon: "close",
    label: "Ruled Out",
  },
  [SubmissionState.Quoted]: {
    className: "filled text-purple-600",
    cardClassName: "bg-purple-500/5 border-purple-500/80",
    icon: "verified",
    label: "Quoted",
  },
};

export const QUOTE_STATE_METADATA: Record<QuoteState, StateMetadata> = {
  [QuoteState.Invalid]: {
    className: "text-slate-600",
    cardClassName: "bg-slate-500/5 border-slate-500/80",
    icon: "verified",
    label: "Invalid",
  },
  [QuoteState.Pending]: {
    className: "filled text-orange-600",
    cardClassName: "bg-orange-500/5 border-orange-500/80",
    icon: "warning",
    label: "Pending",
  },
  [QuoteState.Processed]: {
    className: "filled text-emerald-600",
    cardClassName: "bg-emerald-500/5 border-emerald-500/80",
    icon: "send",
    label: "Ready to Send",
  },
  [QuoteState.Delivered]: {
    className: "filled text-blue-600",
    cardClassName: "bg-blue-500/5 border-blue-500/80",
    icon: "mail",
    label: "Delivered",
  },
  [QuoteState.BindRequestReceived]: {
    className: "text-emerald-600",
    cardClassName: "bg-emerald-500/5 border-emerald-500/80",
    icon: "thumb_up",
    label: "Bind Request Received",
  },
  [QuoteState.Bound]: {
    className: "filled text-purple-600",
    cardClassName: "bg-purple-500/5 border-purple-500/80",
    icon: "verified",
    label: "Bound",
  },
  [QuoteState.NotTaken]: {
    className: "text-red-600",
    cardClassName: "bg-red-500/5 border-red-500/80",
    icon: "thumb_down",
    label: "Not Taken",
  },
};

export const POLICY_STATE_METADATA: Record<PolicyState, StateMetadata> = {
  [PolicyState.AwaitingFromCarrier]: {
    className: "text-orange-600",
    cardClassName: "bg-orange-500/5 border-orange-500/80",
    icon: "hourglass_empty",
    label: "Awaiting Carrier",
  },
  [PolicyState.ReceivedFromCarrier]: {
    className: "text-emerald-600",
    cardClassName: "bg-emerald-500/5 border-emerald-500/80",
    icon: "receipt_long",
    label: "Received from Carrier",
  },
  [PolicyState.Issued]: {
    className: "filled text-purple-600",
    cardClassName: "bg-purple-500/5 border-purple-500/80",
    icon: "verified",
    label: "Issued",
  },
  [PolicyState.Canceled]: {
    className: "filled text-red-600",
    cardClassName: "bg-red-500/5 border-red-500/80",
    icon: "cancel",
    label: "Cancelled",
  },
};

export const STATE_METADATA: Record<States, StateMetadata> = {
  ...MARKETING_PLAN_STATE_METADATA,
  ...SUBMISSION_STATE_METADATA,
  ...QUOTE_STATE_METADATA,
  ...POLICY_STATE_METADATA,
};

export const StateIndicator = ({
  state,
  displayLabel = true,
  className,
}: {
  state: States;
  displayLabel?: boolean;
  className?: string;
}) => {
  const { className: stateClassName, icon, label } = STATE_METADATA[state];

  return (
    <span className={cn("flex gap-[0.5em] items-center", stateClassName, className)}>
      <Icon icon={icon} />
      {displayLabel && <span className="truncate">{label}</span>}
    </span>
  );
};

export const StateLabel = ({ state, className }: { state: States; className?: string }) => {
  const { className: stateClassName, label } = STATE_METADATA[state];
  return <span className={cn(stateClassName, className)}>{label}</span>;
};
