import { UUID } from "@cp/toolkit";
import * as Popover from "@radix-ui/react-popover";
import * as Select from "@radix-ui/react-select";

import { useMyAccount } from "@/auth/useMyAccount";
import { HasInternalRole } from "@/components/has-role";
import { Bar, BarLink } from "@/components/ui/bar";
import { Calendar } from "@/components/ui/calendar";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { PopoverContent } from "@/components/ui/popover";
import { SelectContent, SelectItem } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { StateIndicator } from "@/metadata";
import { useUpdateOpportunity } from "@/opportunity/loaders";
import { cn } from "@/utils";
import { OpportunityDetailsFragment, useBrokerAccountsQuery, useUserAccountsQuery } from "../../../generated/graphql";
import { formatTimezoneDateString } from "../../../utils/date";
import { MarketingPlanDropdown } from "./marketing-plan-dropdown";

export const MarketingPlanHeader = ({ opportunity }: { opportunity: OpportunityDetailsFragment }) => {
  const { toast } = useToast();

  const [updateOpportunity] = useUpdateOpportunity({
    onCompleted: () => {
      toast({ title: "Success", description: "Opportunity updated." });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  const { data: user } = useMyAccount();

  const { data: brokers } = useBrokerAccountsQuery({
    skip: !user?.internal,
    fetchPolicy: "cache-first",
  });

  const { data: agents } = useUserAccountsQuery({
    variables: { input: {} },
    fetchPolicy: "cache-first",
  });

  const now = new Date();
  const { id, insured, broker, desiredEffectiveDate, selectedLinesOfBusiness, state } = opportunity;
  const { agent } = insured;

  const SelectEffectiveDate = ({ children }: { children: React.ReactNode }) => (
    <Popover.Root>
      <Trigger popover={true}>{children}</Trigger>
      <PopoverContent className="p-0 w-auto">
        <Calendar
          mode="single"
          selected={desiredEffectiveDate}
          onSelect={(e) =>
            updateOpportunity({
              variables: {
                input: { id, desiredEffectiveDate: e },
              },
            })
          }
          initialFocus
        />
      </PopoverContent>
    </Popover.Root>
  );

  const SelectAgent = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Select.Root value={agent.id} disabled>
        <Trigger>{children}</Trigger>
        <SelectContent>
          {agents?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );

  const SelectBroker = ({ children }: { children: React.ReactNode }) => (
    <HasInternalRole elseShow={children}>
      <Select.Root
        value={broker ? broker.id : "-"}
        onValueChange={(e) =>
          updateOpportunity({
            variables: {
              input: { id: id, brokerId: e === "-" ? null : (e as UUID) },
            },
          })
        }
      >
        <Trigger>{children}</Trigger>
        <SelectContent>
          <SelectItem value="-">Unassigned</SelectItem>
          {brokers?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );

  return (
    <Card className="rounded-none landscape:rounded-bl-lg sticky top-0 z-20">
      <Bar className="gap-2">
        <div className="flex-auto gap-2 grid grid-flow-col auto-cols-fr h-16 items-center">
          <HeaderCell
            className="p-0"
            title={selectedLinesOfBusiness.join(", ") || "No Coverage Selected"}
            subtitle={<StateIndicator state={state} />}
          />
          <SelectEffectiveDate>
            <HeaderCell
              title={formatTimezoneDateString(desiredEffectiveDate, "MMMM d")}
              subtitle="Eff. Date"
              className={cn(
                !desiredEffectiveDate && "text-muted-foreground",
                now.toISOString() > desiredEffectiveDate && "text-destructive"
              )}
            />
          </SelectEffectiveDate>
          <SelectAgent>
            <HeaderCell title={`${agent.firstName} ${agent.lastName}`} subtitle={agent.agency.name} />
          </SelectAgent>
          <SelectBroker>
            <HeaderCell
              title={broker ? `${broker.firstName} ${broker.lastName}` : "Unassigned"}
              subtitle="Broker"
              className={cn(broker === null && "text-destructive")}
            />
          </SelectBroker>
        </div>
        <MarketingPlanDropdown />
      </Bar>
      <HasInternalRole>
        <Bar className="flex gap-2 items-center justify-start">
          <BarLink to=".">Details</BarLink>
          <BarLink to="files">Files</BarLink>
          <BarLink to="history">History</BarLink>
          <BarLink to="data">Data</BarLink>
        </Bar>
      </HasInternalRole>
    </Card>
  );
};

const Trigger = ({ children, popover = false }: { children: React.ReactNode; popover?: boolean }) => {
  const Component = popover ? Popover.Trigger : Select.Trigger;

  return (
    <Component className="group bg-accent flex items-center justify-between outline-none rounded shadow-contrast">
      {children}
      <Icon
        icon="keyboard_arrow_down"
        className="hidden @2xl/opp:inline mr-3 opacity-50 group-hover:opacity-100 transition-opacity"
      />
    </Component>
  );
};

const HeaderCell = ({
  className,
  title,
  subtitle,
}: {
  className?: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}) => (
  <div className={cn("flex flex-col gap-0.5 px-3 py-1.5 relative rounded text-left truncate", className)}>
    <h5 className="text-2xs @lg/opp:text-xs truncate">{title}</h5>
    <h6 className="text-muted-foreground truncate">{subtitle}</h6>
  </div>
);
