import { createBrowserRouter, Navigate, Outlet, redirect, RouteObject } from "react-router-dom";
import { Roles } from "src/generated/graphql";
import { AdminRoot } from "./admin/admin-root";
import { AgencyOwnership } from "./admin/agency-ownership";
import { AppetiteManagement } from "./admin/appetite-management";
import { AppetiteNote } from "./admin/appetite-note";
import { AppetiteNotesList } from "./admin/appetite-notes-list";
import { AppetiteNotesRoot } from "./admin/appetite-notes-root";
import { EditVertical } from "./admin/edit-vertical";
import { Enablement } from "./admin/enablement";
import { GmailFilters } from "./admin/gmail-filters";
import { Vertical } from "./admin/vertical";
import { VerticalsList } from "./admin/verticals-list";
import { VerticalsRoot } from "./admin/verticals-root";
import { CreateInsuredPage } from "./appetite/pages/create-insured-page";
import { ActivateAccount, activateAccountLoader } from "./auth/ActivateAccount";
import { AuthGuard } from "./auth/AuthGuard";
import { ForgotPassword } from "./auth/ForgotPassword";
import { Login } from "./auth/Login";
import { getAuthedUserAndAgency } from "./auth/user";
import { Centered, Page } from "./components/layout";
import { useTheme } from "./components/theme-provider";
import { ErrorPage } from "./error";
import { FormsRoot } from "./forms/forms-root";
import { HubRoot } from "./hub/hub-root";
import { InboxRoot } from "./inbox/inbox-root";
import { InsuredEmail } from "./insured/insured-email";
import { InsuredFiles } from "./insured/insured-files";
import { InsuredIndex } from "./insured/insured-index";
import { InsuredMarketingPlan } from "./insured/insured-marketing-plan";
import { InsuredPolicies } from "./insured/insured-policies";
import { InsuredQuotes } from "./insured/insured-quotes";
import { InsuredRoot } from "./insured/insured-root";
import { Data } from "./insured/marketing-plan/data";
import { MarketingPlanRoot } from "./insured/marketing-plan/marketing-plan-root";
import { PolicyDetails } from "./insured/policy/policy-details";
import { QuoteDetails } from "./insured/quote/quote-details";
import { InsuredsRoot } from "./insureds/insureds-root";
import { Kanban } from "./kanban/kanban";
import { QuoteBind } from "./opportunity/components/actions/quote-bind";
import { Files } from "./opportunity/files/files";
import { History } from "./opportunity/history";
import { Markets } from "./opportunity/markets/markets";
import { opportunityRoutes } from "./opportunity/routes";
import { PageNotFound } from "./page-not-found";
import { FirstSubmissionPage } from "./public/first-submission-page";

const Beef = () => {
  const { setTheme } = useTheme();
  setTheme("beef");
  return <Navigate to="/" />;
};

export const roleHomepageLoader = async () => {
  const { user } = (await getAuthedUserAndAgency()) || {};
  let path = "/login";

  if (user) {
    if (user && user.roles.includes(Roles.Broker)) {
      path = `/bankan/${user.id}`;
    } else if (user.roles.includes(Roles.Admin)) {
      path = "/bankan";
    } else {
      path = "/insureds";
    }
  }

  return redirect(path);
};

const authProtectedRoutes: RouteObject[] = [
  { path: "agency", loader: () => redirect("/insureds") },
  {
    path: "admin",
    element: <AdminRoot />,
    children: [
      {
        path: "appetite",
        element: <AppetiteManagement />,
      },
      {
        path: "appetite-notes",
        element: <AppetiteNotesRoot />,
        children: [
          {
            index: true,
            element: <AppetiteNotesList />,
          },
          {
            path: "create",
            element: <AppetiteNote mode="create" />,
          },
          {
            path: ":appetiteNoteId/edit",
            element: <AppetiteNote mode="edit" />,
          },
        ],
      },
      {
        path: "agency-ownership",
        element: <AgencyOwnership />,
      },
      {
        path: "enablement",
        element: <Enablement />,
      },
      {
        path: "gmail-filters",
        element: <GmailFilters />,
      },
      {
        path: "verticals",
        element: <VerticalsRoot />,
        children: [
          {
            index: true,
            element: <VerticalsList />,
          },
          {
            path: "create",
            element: <EditVertical />,
          },
          {
            path: ":verticalId",
            element: <Vertical />,
          },
          {
            path: ":verticalId/edit",
            element: <EditVertical />,
          },
        ],
      },
    ],
  },
  {
    // Note: This root path is for testing. Without a clientId, the form won't save
    path: "supplementals",
    element: <FormsRoot />,
    children: [
      {
        // Note: This path is what users should be routed to
        path: ":clientId",
      },
    ],
  },
  {
    path: "opportunity",
    element: <HubRoot />,
    children: [...opportunityRoutes({ path: "opportunity" })],
  },
  {
    path: "bankan/:brokerId?",
    element: <Kanban />,
  },
  {
    path: "inbox",
    element: <InboxRoot />,
  },
  {
    path: "insureds/:agencyId?",
    element: <InsuredsRoot />,
  },
  {
    path: "insured/new",
    element: <CreateInsuredPage />,
  },
  {
    path: "insured/:insuredId",
    element: <InsuredRoot />,
    children: [
      {
        index: true,
        element: <InsuredIndex />,
      },
      {
        path: "email",
        element: <InsuredEmail />,
      },
      {
        path: "files",
        element: <InsuredFiles />,
      },
      {
        path: "quotes",
        element: <InsuredQuotes />,
        children: [
          {
            path: ":quoteId",
            element: <QuoteDetails />,
          },
        ],
      },
      {
        path: "quotes/:quoteId/bind",
        element: <QuoteBind />,
      },
      {
        path: "policies",
        element: <InsuredPolicies />,
        children: [
          {
            path: ":policyId",
            element: <PolicyDetails />,
          },
        ],
      },
      {
        path: ":opportunityId",
        element: <InsuredMarketingPlan />,
        children: [
          {
            index: true,
            element: <MarketingPlanRoot />,
          },
          {
            path: "data",
            element: <Data />,
          },
          {
            path: "files",
            element: <Files />,
          },
          {
            path: "history",
            element: <History />,
          },
          {
            path: "markets",
            element: <Markets />,
          },
        ],
      },
    ],
  },
  { index: true, loader: roleHomepageLoader, element: <div /> },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Page>
        <Outlet />
      </Page>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: authProtectedRoutes,
      },
    ],
  },
  {
    path: "first-submission",
    element: <FirstSubmissionPage />,
  },
  {
    element: <Centered />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "activate-account/:changePasswordId",
        loader: activateAccountLoader,
        element: <ActivateAccount />,
      },
    ],
  },
  {
    path: "appetite/*",
    loader: () => redirect("/new"),
  },
  {
    path: "broker/*",
    loader: () => redirect("/new"),
  },
  {
    path: "beef",
    element: <Beef />,
  },
  {
    path: "*",
    element: (
      <Page>
        <PageNotFound />
      </Page>
    ),
  },
]);
