import { Bar } from "@/components/ui/bar";
import { Pane, PaneGroup } from "@/components/ui/pane";
import { useNavigate, useParams } from "react-router";
import { useCreateOpportunityMutation, useInsuredQuery } from "../../generated/graphql";
import { UUIDScalar } from "../../utils/types";
import { Button } from "../components/ui/button";
import { ApplicantClassification } from "../opportunity/info/applicant-classification";
import { ApplicantDetails } from "../opportunity/info/applicant-details";

export const InsuredIndex: React.FC = () => {
  const { insuredId } = useParams();
  const { data } = useInsuredQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const insured = data?.insured;

  if (!insured) {
    return null;
  }

  return (
    <PaneGroup>
      <Bar as="header"></Bar>
      <Pane className="flex flex-col gap-3">
        <ApplicantDetails insured={insured} />
        <ApplicantClassification insured={insured} />
        <CreateMarketingPlanButton insuredId={insured.id} />
      </Pane>
    </PaneGroup>
  );
};

const CreateMarketingPlanButton = ({ insuredId }: { insuredId: string }) => {
  const navigate = useNavigate();
  const [createOpportunity] = useCreateOpportunityMutation({
    onCompleted: (data) => navigate(`${data.createOpportunity.id}`),
  });

  return (
    <Button
      onClick={() => {
        void createOpportunity({
          variables: {
            input: {
              insuredId: insuredId as UUIDScalar,
              desiredEffectiveDate: new Date(),
            },
          },
          onCompleted: (data) => {
            navigate(`/insured/${insuredId}/${data.createOpportunity.id}`);
          },
          refetchQueries: ["InsuredOpportunities"],
        });
      }}
    >
      Create Marketing Plan
    </Button>
  );
};
