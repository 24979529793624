import { Icon } from "@/components/ui/icon";
import { StateIndicator, STATE_METADATA } from "@/metadata";
import { cn } from "@/utils";
import { Link } from "react-router-dom";
import { BankanQuotesQuery, QuoteState } from "src/generated/graphql";
import { formatDollarsAndCents } from "src/utils/strings";

type BankanInsured = BankanQuotesQuery["bankanQuotes"]["groupedQuotes"][number];

export const QuotesCard = ({ insured, highlightState }: { insured: BankanInsured; highlightState: QuoteState }) => {
  const { insuredId, insured: argh, quotes } = insured;
  return (
    <div key={insuredId}>
      <header className="group bg-muted border border-b-0 flex gap-1 items-center justify-between px-4 pt-3 pb-5 rounded-t-lg text-xs">
        <span className="font-semibold truncate">{argh.name}</span>
      </header>
      <div className="bg-background -mt-2 rounded-lg">
        {quotes.map((quote) => {
          const { id, premium, state, appetiteProduct } = quote;
          const { cardClassName } = STATE_METADATA[state];
          return (
            <Link
              key={id}
              to={`/insured/${insuredId}/quotes/${id}`}
              className={cn(
                "group border flex flex-col -mt-px px-4 py-3 space-y-1.5 first:rounded-t-lg last:rounded-b-lg",
                state === highlightState && `${cardClassName} relative z-1`
              )}
            >
              <div className="flex font-semibold gap-4 items-start justify-between text-xs">
                <span className="truncate">
                  {appetiteProduct.carrierName}: {appetiteProduct.carrierProductName}
                </span>
                <Icon icon="arrow_forward" className="opacity-25 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="flex gap-4 justify-between text-2xs">
                <StateIndicator state={state} />
                <span className="whitespace-nowrap">{formatDollarsAndCents(premium ?? 0)}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
